<template>
  <div class="profile-config-form__field col-md-4">
    <div class="profile-config-form__field-title">{{ __('profile_config_form.height') }}</div>

    <validation-provider
        tag="span"
        :vid="`height-${_uid}`"
        :name="__('profile_config_form.height')"
        rules="required|numeric|between:60,220"
        v-slot="{ errors }"
    >
      <input type="number"
             class="input input--profile"
             v-model="value"
             :placeholder="__('profile_config_form.placeholder')"
      />
      <span v-if="errors.length > 0" class="input__error">{{ errors[0] }}</span>
    </validation-provider>
  </div>
</template>

<script>
import ProfileConfigFormField from '@/mixins/profile-config-form-field';
import Locales from '@/mixins/locales';

export default {
  name: 'height',
  mixins: [
    Locales,
    ProfileConfigFormField('height', ''),
  ],
};
</script>
